import React, { Component } from 'react'
import Helmet from 'react-helmet'
import isEmpty from 'lodash.isempty'

import config from '../../../config'
import HeroSlider from '../../partials/blog/HeroSlider'
import PostsList from '../../partials/blog/PostsList'
import Search from '../../partials/blog/Search'
import { ListHeader } from '../../partials/blog/styles'
import Pagination from '../Pagination'

const websiteSchemaOrgJSONLD = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  url: config.siteUrl,
  name: config.siteTitle,
  alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
}

class CategoryTemplate extends Component {
  render () {
    const { posts, pageContext, category, cta, searchPlaceholder } = this.props
    const { currentPage, numPages, featured } = pageContext

    return (
      <>
        <Helmet>
          {/* Schema.org tags */}
          <script type='application/ld+json'>
            {JSON.stringify(websiteSchemaOrgJSONLD)}
          </script>
        </Helmet>
        {featured.length ? (
          <section className='hero'>
            <HeroSlider items={featured} />
          </section>
        ) : ''}
        <section className='section'>
          <div className='container'>
            <div className='content'>
              <div className='columns'>
                <div className='column is-10 is-offset-1'>
                  <ListHeader>
                    <h1>{category}</h1>
                    <Search placeholder={searchPlaceholder} />
                  </ListHeader>
                  <div className='columns is-multiline'>
                    <PostsList posts={posts} />
                  </div>
                </div>
              </div>
            </div>
            <div className='buttons is-centered'>
              <Pagination
                lang={config.lang}
                currentPage={currentPage}
                numPages={numPages}
                contextPage={`category/${category}`}
              />
            </div>
          </div>
        </section>
        {!isEmpty(cta) && (
          <div className='hp-banner2'>
            <div className='container'>
              <div className='columns'>
                <div className='column'>
                  <h2>{cta.heading}</h2>
                  <h3>{cta.lead}</h3>
                </div>
                {cta.button && (
                  <div className='column trialelement'>
                    <a className='button free-trial-btn2' href={cta.button.url}>
                      {cta.button.text}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default CategoryTemplate
