import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import CategoryTemplate from '../components/CategoryTemplate'
import Layout from '../components/Layout'
import { removeEmptyProperties } from '../utils/helpers'

const CategoryPage = ({ data, pageContext, location }) => {
  const categories = data.categories.frontmatter.categories.translations
  const page = data.page.frontmatter
  const posts = data.posts.edges

  return (
    <Layout location={location}>
      <Helmet>
        <title>{page.meta_title}</title>
        <meta name='description' content={page.meta_description} />
      </Helmet>
      <CategoryTemplate
        posts={posts}
        pageContext={pageContext}
        category={categories[pageContext.category]}
        cta={removeEmptyProperties(page.cta)}
        searchPlaceholder={page.search_placeholder}
      />
    </Layout>
  )
}

CategoryPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.object,
  }),
}

export default CategoryPage

export const query = graphql`
  query blogPostsListByCategory($category: String, $skip: Int!, $limit: Int!) {
    page: markdownRemark(
      frontmatter: { templateKey: { eq: "category-page" } }
    ) {
      frontmatter {
        meta {
          meta_title
          meta_description
        }
        cta {
          heading
          lead
          button {
            text
            url
          }
        }
        search_placeholder
      }
    }
    categories: markdownRemark(fileAbsolutePath: { regex: "/header-blog/" }) {
      frontmatter {
        categories {
          translations {
            software
            industria
            gestao
            fiscal_e_legal
          }
        }
      }
    }
    posts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          timeToRead
          excerpt(pruneLength: 119)
          fields {
            slug
          }
          frontmatter {
            title
            cover
            category
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
